import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import VideosNav from '../../../components/videos-nav'
import LookingForMore from '../../../components/looking-for-more'
import ResourcesNav from '../../../components/resources-nav'
import Layout from '../../../components/layout'

export default class Videos extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Watch the ${siteTitle} Videos in your classroom for free! Intro, PreK & Elementary number sense levels of instruction.`
    return (
      <Layout pageTitle="Videos" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <h1>Videos</h1>
        <hr />
        <VideosNav path={this.props.path} />
        <hr />
        <LookingForMore />
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
